<template>
	<div>
		<base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center">
			<span class="mask bg-gradient-red opacity-8"></span>
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-sm-12">
						<h1 class="display-2 text-white">Dashboard</h1>
						<p class="text-white mt-0 mb-0"></p>
					</div>
				</div>
			</div>
		</base-header>
		<!-- <div class="container-fluid mt--4">
			<card :shadow="true"> Dashboard data </card>
		</div> -->

		<div class="container-fluid mt--4">
			<card :shadow="true">
				<div class="col-6 mb-5">
					<div class="display-4">Bookings</div>
				</div>
				<div class="table-responsive mt-5 ml-1">
					<base-table
						class="align-items-center"
						thead-classes="thead-light"
						type="hover"
						:data="tableData"
					>
						<template slot="columns">
							<th>Sl</th>
							<th
								v-for="(header, index) in tableHeader"
								:key="index"
							>
								{{ header }}
							</th>
							<th>Source</th>
							<th>Status</th>
							<th>Actions</th>
						</template>
						<template slot-scope="{ row, index }">
							<td
								@click.prevent="handleView(row)"
								style="cursor: pointer"
							>
								{{
									itemsPerPage * (currentPage - 1) + index + 1
								}}
							</td>
							<td
								@click.prevent="handleView(row)"
								style="cursor: pointer"
							>
								{{ row.name }}
							</td>
							<td
								@click.prevent="handleView(row)"
								style="cursor: pointer"
							>
								{{ row.mobile }}
							</td>
							<td
								@click.prevent="handleView(row)"
								style="cursor: pointer"
							>
								{{ row.pickup_date }}
							</td>
							<td
								@click.prevent="handleView(row)"
								style="cursor: pointer"
							>
								{{ row.pickup_time }}
							</td>

							<td
								@click.prevent="handleView(row)"
								style="cursor: pointer"
							>
								{{
									row.app_booking === true ? "App" : "Website"
								}}
							</td>
							<td class="text-white">
								<span
									:class="
										row.status === 'In Review'
											? 'bg-info p-1 rounded'
											: row.status === 'Approved'
											? 'bg-success p-1 rounded'
											: row.status === 'Completed'
											? 'bg-primary p-1 rounded'
											: 'bg-danger p-1 rounded'
									"
									>{{ row.status }}</span
								>
							</td>
							<td class="text-center">
								<base-button
									class="btn btn-sm btn-primary"
									@click="handleView(row)"
								>
									<i class="fas fa-eye"></i>
								</base-button>
								<base-button
									class="btn btn-sm btn-success"
									@click="changeStatus(row)"
								>
									Change Status
								</base-button>
							</td>
						</template>
					</base-table>
				</div>
				<base-pagination
					class="mt-5"
					:perPage="itemsPerPage"
					:total="bookingsCount"
					v-model="currentPage"
					align="center"
				/>
				<modal
					class="modal"
					:show="showModal"
					footerClasses="pt-0 pb-1"
					headerClasses="	"
					@close="showModal = false"
					modal-classes=" modal-dialog-centered  modal-md"
					type="mini"
				>
					<template v-slot:header>
						<div class="row">
							<h3 class="col-sm">Update Status</h3>
						</div>
					</template>
					<div class="mb-2">
						<label for="" class="">Status</label>
						<select class="form-control" v-model="status">
							<option value="">Choose</option>
							<option value="in_review">In Review</option>
							<option value="approved">Approved</option>
							<option value="cancelled">Cancelled</option>
							<option value="completed">Completed</option>
						</select>
					</div>

					<template v-slot:footer>
						<base-button
							type="danger pl-4 pr-4 "
							class="mb-2 btn btn-sm"
							@click.prevent="showModal = false"
							>Cancel</base-button
						>
						<base-button
							type="success pl-4 pr-4"
							class="mb-2 btn btn-sm"
							@click.prevent="editStatus"
							>Save</base-button
						>
					</template>
				</modal>
				<!-- Details Modal -->
				<modal
					class="modal"
					:show="showDetailsModal"
					footerClasses="pt-0 pb-1"
					headerClasses="	"
					@close="showDetailsModal = false"
					modal-classes=" modal-dialog-center  modal-md"
					type="mini"
				>
					<template v-slot:header>
						<div class="row">
							<h3 class="col-sm">Booking Details</h3>
						</div>
					</template>
					<div class="mb-2">
						<div
							class="d-flex justify-content-between align-items-center"
						>
							<h3 class="pr-3">Name :</h3>
							<p>{{ details.name }}</p>
						</div>
						<div
							class="d-flex justify-content-between align-items-center"
						>
							<h3 class="">Phone :</h3>
							<p>{{ details.mobile }}</p>
						</div>
						<div
							class="d-flex justify-content-between align-items-center"
						>
							<h3 class="">Pickup Date :</h3>
							<p>{{ details.pickup_date }}</p>
						</div>
						<div
							class="d-flex justify-content-between align-items-center"
						>
							<h3 class="">Pickup Time :</h3>
							<p>{{ details.pickup_time }}</p>
						</div>
						<div
							class="d-flex justify-content-between align-items-center"
						>
							<h3 class="">Booking Source :</h3>
							<p>{{ details.app_booking ? "App" : "Website" }}</p>
						</div>
						<hr class="my-3" />
						<div class="pt-2">
							<h3 class="text-center">Pickup Address :</h3>
							<p class="text-center">
								{{ details.pickup_location }}
							</p>
						</div>
						<div class="">
							<h3 class="text-center">Dropoff Address :</h3>
							<p class="text-center">
								{{ details.drop_location }}
							</p>
						</div>
					</div>

					<template v-slot:footer>
						<base-button
							type="danger pl-4 pr-4 "
							class="mb-2 btn btn-sm"
							@click.prevent="showDetailsModal = false"
							>Close</base-button
						>
					</template>
				</modal>
			</card>
		</div>
	</div>
</template>
<script>
import BaseButton from "../components/BaseButton.vue";
export default {
	components: { BaseButton },
	name: "users",
	data() {
		return {
			currentPage: 1,
			itemsPerPage: 20,
			showModal: false,
			showDetailsModal: false,
			status: "",
			statusData: {},
			details: {},
		};
	},
	computed: {
		tableHeader() {
			return this.$store.state.UsersData.tableHeaders;
		},
		tableData() {
			return this.$store.state.UsersData.tableData;
		},
		bookingsCount() {
			return this.$store.state.UsersData.bookingCount;
		},
		// pagedTableData() {
		// 	var between = (this.currentPage - 1) * this.itemsPerPage;
		// 	return this.tableData.slice(between, between + this.itemsPerPage);
		// },
		editBooking() {
			return localStorage.getItem("edit_booking");
		},
	},
	methods: {
		fetchBookingList() {
			this.$store
				.dispatch("fetchBookingList", {
					offset: (this.currentPage - 1) * this.itemsPerPage,
					limit: this.itemsPerPage,
				})
				.catch((err) => {
					console.log(err);
					this.$notify({
						title: "Failed to fetch booking list",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},

		changeStatus(row) {
			this.showModal = true;
			this.statusData = row;
		},
		editStatus() {
			this.statusData.status = this.status;
			this.$store
				.dispatch("setBookingDetails", this.statusData)
				.then(() => {
					this.$notify({
						title: "Status Changed",
						icon: "fa fa-check",
						type: "success",
					});
					this.fetchBookingList();
					this.showModal = false;
				})
				.catch((err) => {
					this.$notify({
						title: "Failed to change status",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: err.toString(),
					});
				});
		},
		handleView(row) {
			this.showDetailsModal = true;
			this.details = row;
			console.log(row);
			console.log(this.details);
		},
	},
	watch: {
		currentPage() {
			this.fetchBookingList();
		},
	},
	mounted() {
		this.fetchBookingList();
	},
};
</script>
<style scoped>
.skeleton {
	padding: 15px;
	max-width: 200px;
	width: 100%;
	/* background: #fff; */
	margin-bottom: 20px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12); */
}
.skeleton .line {
	height: 15px;
	margin-bottom: 6px;
	border-radius: 2px;
	background: rgba(130, 130, 130, 0.2);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(8%, rgba(130, 130, 130, 0.2)),
		color-stop(18%, rgba(130, 130, 130, 0.3)),
		color-stop(33%, rgba(130, 130, 130, 0.2))
	);
	background: linear-gradient(
		to right,
		rgba(130, 130, 130, 0.2) 8%,
		rgba(130, 130, 130, 0.3) 18%,
		rgba(130, 130, 130, 0.2) 33%
	);
	background-size: 800px 100px;
	animation: wave-lines 2s infinite ease-out;
}
.skeleton .line:last-child {
	margin-bottom: 0;
}
.skeleton-left {
	flex: 2;
	padding-right: 15px;
}
@keyframes wave-lines {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}
@keyframes wave-squares {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}
</style>
